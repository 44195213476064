// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-2009-mensajes-js": () => import("./../../../src/pages/2009mensajes.js" /* webpackChunkName: "component---src-pages-2009-mensajes-js" */),
  "component---src-pages-2010-mensajes-js": () => import("./../../../src/pages/2010mensajes.js" /* webpackChunkName: "component---src-pages-2010-mensajes-js" */),
  "component---src-pages-2011-mensajes-js": () => import("./../../../src/pages/2011mensajes.js" /* webpackChunkName: "component---src-pages-2011-mensajes-js" */),
  "component---src-pages-2012-mensajes-js": () => import("./../../../src/pages/2012mensajes.js" /* webpackChunkName: "component---src-pages-2012-mensajes-js" */),
  "component---src-pages-2013-mensajes-js": () => import("./../../../src/pages/2013mensajes.js" /* webpackChunkName: "component---src-pages-2013-mensajes-js" */),
  "component---src-pages-2015-mensajes-js": () => import("./../../../src/pages/2015mensajes.js" /* webpackChunkName: "component---src-pages-2015-mensajes-js" */),
  "component---src-pages-2017-mensajes-js": () => import("./../../../src/pages/2017mensajes.js" /* webpackChunkName: "component---src-pages-2017-mensajes-js" */),
  "component---src-pages-2018-mensajes-js": () => import("./../../../src/pages/2018mensajes.js" /* webpackChunkName: "component---src-pages-2018-mensajes-js" */),
  "component---src-pages-2019-mensajes-js": () => import("./../../../src/pages/2019mensajes.js" /* webpackChunkName: "component---src-pages-2019-mensajes-js" */),
  "component---src-pages-2023-mensajes-js": () => import("./../../../src/pages/2023mensajes.js" /* webpackChunkName: "component---src-pages-2023-mensajes-js" */),
  "component---src-pages-efesios-js": () => import("./../../../src/pages/efesios.js" /* webpackChunkName: "component---src-pages-efesios-js" */),
  "component---src-pages-exodo-js": () => import("./../../../src/pages/exodo.js" /* webpackChunkName: "component---src-pages-exodo-js" */),
  "component---src-pages-genesis-js": () => import("./../../../src/pages/genesis.js" /* webpackChunkName: "component---src-pages-genesis-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-levitico-js": () => import("./../../../src/pages/levitico.js" /* webpackChunkName: "component---src-pages-levitico-js" */),
  "component---src-pages-numeros-js": () => import("./../../../src/pages/numeros.js" /* webpackChunkName: "component---src-pages-numeros-js" */),
  "component---src-pages-publicaciones-js": () => import("./../../../src/pages/publicaciones.js" /* webpackChunkName: "component---src-pages-publicaciones-js" */)
}

